const getEmployeeByToken = async (token) =>
{
  const employee = await fetch('https://jinglejill.com:4100/seekjob/getEmployeeByToken/',//**
  {
    method: 'POST',
    headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',//**
              },
    body: JSON.stringify({
      token: token,
      modifiedUser: "jill",
    })
  })
  .then((response) => response.json())
  .then((responseData) =>{
    console.log("responseData getEmployeeByToken:"+JSON.stringify(responseData));
    if(responseData.success == true)
    {
      // setEmployee(responseData.employee);
      return responseData.employee;
    }
    else
    {
      // error message
      console.log(responseData.message);
    }
  }).catch((error) => console.error(error))

  return employee;
}

const getEmployeeResumeByEmployeeID = async (employeeID) =>
{
  const result = await fetch('https://jinglejill.com:4100/seekjob/getEmployeeResumeByEmployeeID/',//**
  {
    method: 'POST',
    headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',//**
              },
    body: JSON.stringify({
      employeeID: employeeID,
      modifiedUser: "jill",
    })
  })
  .then((response) => response.json())
  .then((responseData) =>{
    console.log("responseData getEmployeeResume:"+JSON.stringify(responseData));
    if(responseData.success == true)
    {
      // setEmployee(responseData.employee);
      return responseData;
    }
    else
    {
      // error message
      console.log(responseData.message);
    }
  }).catch((error) => console.error(error))

  return result;
}

const deleteEmployeeResume = async (employeeResumeID) =>
{
  const result = await fetch('https://jinglejill.com:4100/seekjob/deleteEmployeeResume/',//**
  {
    method: 'POST',
    headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',//**
              },
    body: JSON.stringify({
      employeeResumeID: employeeResumeID,
      modifiedUser: 'jill',
    })
  })
  .then((response) => response.json())
  .then((responseData) =>{
    console.log("responseData deleteEmployee:"+JSON.stringify(responseData));
    if(responseData.success == true)
    {
//      setEmployee(responseData.employee);
      return responseData.success;
    }
    else
    {
      // error message
      console.log(responseData.message);
    }
  }).catch((error) => console.error(error))
  return result;
}

const loginEmployee = async (fbUserID,name,email,token) =>
{
  const employee = await fetch('https://jinglejill.com:4100/seekjob/loginEmployeeByFBUserID/',//**
  {
    method: 'POST',
    headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',//**
              },
    body: JSON.stringify({
      fbUserID: fbUserID,
      name: name,
      email: email,
      token: token,
      modifiedUser: name,
    })
  })
  .then((response) => response.json())
  .then((responseData) =>{
    console.log("responseData loginEmployee:"+JSON.stringify(responseData));
    if(responseData.success == true)
    {
      return responseData.employee;
    }
    else
    {
      // error message
      console.log(responseData.message);
    }
  }).catch((error) => console.error(error))
  return employee;
}

const getImage = async (imageUrl) =>
{
  const base64Src = await fetch('https://jinglejill.com:4100/seekjob/getImage/',//**
  {
    method: 'POST',
    headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',//**
              },
    body: JSON.stringify({
      imageUrl: imageUrl,
      modifiedUser: "jill",
    })
  })
  .then((response) => response.json())
  .then((responseData) =>{
    console.log("responseData getImage:"+JSON.stringify(responseData));
    if(responseData.success == true)
    {
      return responseData.base64Src;
    }
    else
    {
      // error message
      console.log(responseData.message);
    }
  }).catch((error) => console.error(error));
  
  return base64Src;
}

export default getEmployeeByToken;
export {loginEmployee,getEmployeeResumeByEmployeeID,deleteEmployeeResume,getImage}
