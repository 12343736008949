/**
=========================================================
* Argon Dashboard 2 MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-material-ui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from '@mui/material/CircularProgress';

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import ArgonAlert from "components/ArgonAlert";

// Argon Dashboard 2 MUI example components
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import PlaceholderCard from "examples/Cards/PlaceholderCard";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import profilesListData from "layouts/profile/data/profilesListData";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

//facebookLogin
import FacebookLogin from 'react-facebook-login';

// Authentication layout components
import Separator from "layouts/authentication/components/Separator";

// react-router-dom components
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import {useState, useCallback, useEffect, useContext} from 'react';
import useToken from 'components/app/useToken';
import {loginEmployee} from 'components/app/dbQuery';
import EmployeeContext from 'components/app/employee';

const bgImage =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/profile-layout-header.jpg";

function EmployerSignUp() {
  
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertText, setAlertText] = useState('');
  const [loading, setLoading] = useState(false);
  
  const { token, setToken } = useToken();
  const { employee, setEmployee } = useContext(EmployeeContext);
  
  const responseFacebook = async (response) => {
    console.log("fb response:"+JSON.stringify(response));
    
    //save token
    setToken({token:response.accessToken});
    
    //check ว่ามี employeeid รึยัง ถ้ายังให้สร้าง employeeID
    const _employee = await loginEmployee(response.userID,response.name,response.email,response.accessToken);
    console.log("_employee:"+JSON.stringify(_employee));
    setEmployee(_employee);
  }

  const componentClicked = (response) => {
    console.log(response);
  }
  
  useEffect(()=>
  {
    const redirect = searchParams.get("redirect");
    console.log("redirect:"+redirect);
    if(employee)
    {
      switch(redirect)
      {
        case "resume-submit":navigate('/resume-submit',{state:{employee:employee}});break;
        default:navigate('/dashboard');
      }
    }
  },[employee])
  
  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };
  
  async function sha256(message) {
      // encode as UTF-8
      const msgBuffer = new TextEncoder().encode(message);

      // hash the message
      const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);

      // convert ArrayBuffer to Array
      const hashArray = Array.from(new Uint8Array(hashBuffer));

      // convert bytes to hex string
      const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
      return hashHex;
  }

  
  const handleSignUp = () =>
  {
    if(name.trim() == "")
    {
      setShowAlert(true);
      setTimeout(()=>setShowAlert(false),5000);
      setAlertText("กรุณาใส่ชื่อ");
      setTimeout(()=>setAlertText(''),5000);
      return;
    }
    
    if(!validateEmail(email.trim()))
    {
      setShowAlert(true);
      setTimeout(()=>setShowAlert(false),5000);
      setAlertText("อีเมลไม่ถูกต้อง");
      setTimeout(()=>setAlertText(''),5000);
      return;
    }
    
    setLoading(true);
    
    fetch('https://jinglejill.com:4100/seekjob/employeeSignUp',//**
    {
      method: 'POST',
      headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',//**
                },
      body: JSON.stringify({
        name: name.trim(),
        email: email.trim(),
        password: sha256(password),
        modifiedUser: "jill",
      })
    })
    .then((response) => response.json())
    .then((responseData) =>{
      console.log("responseData:"+JSON.stringify(responseData));
      setLoading(false);
      if(responseData.success == true)
      {
        console.log("sign up resume");
        setEmployee(responseData.employee);
      }
      else
      {
        // error message
        console.log(responseData.message);
        setShowAlert(true);
        setTimeout(()=>setShowAlert(false),5000);
        setAlertText(responseData.msg);
        setTimeout(()=>setAlertText(''),5000);
 
      }
    }).catch((error) => console.error(error))
  }
  return (

        <DashboardLayout
          sx={{
            backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.info.main, 0.6),
                rgba(gradients.info.state, 0.6)
              )}, url(${bgImage})`,
            backgroundPositionY: "50%",
          }}
        >
          {!employee && (<DashboardNavbar/>)}
          {employee && (<DashboardNavbar employeeName={employee.Name}/>)}
          <ArgonBox mt={45} mb={3}>
          </ArgonBox>
          {/*
          <Card>
            <ArgonBox p={3} mb={1} textAlign="center">
              <ArgonTypography variant="h5" fontWeight="medium">
                Register with
              </ArgonTypography>
            </ArgonBox>
            <ArgonBox mb={2} display="flex" justifyContent="center">
              <FacebookLogin
                appId="640093011161507"
                autoLoad={true}
                fields="name,email,picture"
                onClick={componentClicked}
                callback={responseFacebook} />
            </ArgonBox>
            <ArgonBox px={12}>
              <Separator />
            </ArgonBox>
            <ArgonBox pt={2} pb={3} px={3}>
              {
                showAlert && (<ArgonBox><ArgonAlert color="error">{alertText}</ArgonAlert></ArgonBox>)
              }
              <ArgonBox component="form" role="form">
                <ArgonBox mb={2}>
                  <ArgonInput placeholder="Name" value={name} onChange={(e)=>setName(e.target.value)}/>
                </ArgonBox>
                <ArgonBox mb={2}>
                  <ArgonInput type="email" placeholder="Email" value={email} onChange={(e)=>setEmail(e.target.value)}/>
                </ArgonBox>
                <ArgonBox mb={2}>
                  <ArgonInput type="password" placeholder="Password" value={password} onChange={(e)=>setPassword(e.target.value)} />
                </ArgonBox>
                <ArgonBox display="flex" alignItems="center">
                  <Checkbox defaultChecked />
                  <ArgonTypography
                    variant="button"
                    fontWeight="regular"
                    sx={{ cursor: "pointer", userSelect: "none" }}
                  >
                    &nbsp;&nbsp;I agree the&nbsp;
                  </ArgonTypography>
                  <ArgonTypography
                    component="a"
                    href="#"
                    variant="button"
                    fontWeight="bold"
                    textGradient
                  >
                    Terms and Conditions
                  </ArgonTypography>
                </ArgonBox>
                <ArgonBox mt={4} mb={1}>
                  <ArgonButton variant="gradient" color="dark" fullWidth onClick={handleSignUp}>
                    {!loading && "sign up"}
                    {loading && (<CircularProgress size='1rem' color='white' thickness={4}/>)}
                  </ArgonButton>
                </ArgonBox>
                <ArgonBox mt={2}>
                  <ArgonTypography variant="button" color="text" fontWeight="regular">
                    Already have an account?&nbsp;
                    <ArgonTypography
                      component={Link}
                      to="/authentication/sign-in"
                      variant="button"
                      color="dark"
                      fontWeight="bold"
                      textGradient
                    >
                      Sign in
                    </ArgonTypography>
                  </ArgonTypography>
                </ArgonBox>
              </ArgonBox>
            </ArgonBox>
          </Card>
            */}
          </DashboardLayout>
          
  );
}

export default EmployerSignUp;
