/**
=========================================================
* Argon Dashboard 2 MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-material-ui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Soft UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.
  Once you add a new route on this file it will be visible automatically on
  the Sidenav.
  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Argon Dashboard 2 MUI layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import VirtualReality from "layouts/virtual-reality";
import RTL from "layouts/rtl";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";

import JobPost from "layouts/job-post";
import EmployeeSearch from "layouts/employee-search";
import EmployeeInterestedInUs from "layouts/employee-interested-in-us";
import JobSearch from "layouts/job-search";
import EmployeeSignUp from "layouts/employee-sign-up";
import EmployeeSignIn from "layouts/employee-sign-in";
import ResumeSubmit from "layouts/resume-submit";
import SignOut from "layouts/sign-out";
import EmployerSignUp from "layouts/employer-sign-up";



import Icon from "@mui/material/Icon";

const routes = [
  {
    type: "route",
    name: "หน้าหลัก",
    key: "dashboard",
    route: "/dashboard",
    icon: <ArgonBox component="i" color="primary" fontSize="14px" className="ni ni-tv-2" />,
    component: <Dashboard />,
  },
  { type: "title", title: "สำหรับนายจ้าง", key: "employer-pages" },
  {
    type: "route",
    name: "โพสงาน",
    key: "job-post",
    route: "/job-post",
    icon: <ArgonBox component="i" color="primary" fontSize="14px" className="ni ni-write" />,
    component: <JobPost />,
  },
  {
    type: "route",
    name: "ค้นหาคนทำงาน",
    key: "employee-search",
    route: "/employee-search",
    icon: <ArgonBox component="i" color="primary" fontSize="14px" className="ni ni-search" />,
    component: <EmployeeSearch />,
  },
  {
    type: "route",
    name: "ดูคนทำงานที่สนใจเรา",
    key: "employee-interested-in-us",
    route: "/employee-interested-in-us",
    icon: <ArgonBox component="i" color="primary" fontSize="14px" className="ni ni-blue-heart" />,
    component: <EmployeeInterestedInUs />,
  },
  {
    type: "route",
    name: "ลงทะเบียนนายจ้าง",
    key: "employer-sign-up",
    route: "/employer-sign-up",
    icon: <ArgonBox component="i" color="info" fontSize="14px" className="ni ni-collection" />,
    component: <EmployerSignUp />,
  },
  { type: "title", title: "สำหรับลูกจ้าง", key: "employee-pages" },
  {
    type: "route",
    name: "ฝากประวัติ",
    key: "resume-submit",
    route: "/resume-submit",
    icon: <Icon color="success">upload
    </Icon>,
    component: <ResumeSubmit />,
  },
  {
    type: "route",
    name: "ค้นหางาน",
    key: "job-search",
    route: "/job-search",
    icon: <ArgonBox component="i" color="primary" fontSize="14px" className="ni ni-search" />,
    component: <JobSearch />,
  },
  {
    type: "route",
    name: "Sign in",
    key: "employee-sign-in",
    route: "/employee-sign-in",
    icon: <Icon color="info">account_circle</Icon>,
    component: <EmployeeSignIn />,
  },
  {
    type: "route",
    name: "Sign up",
    key: "employee-sign-up",
    route: "/employee-sign-up",
    icon: <ArgonBox component="i" color="info" fontSize="14px" className="ni ni-collection" />,
    component: <EmployeeSignUp />,
  },
//  {
//    type: "route",
//    name: "Tables",
//    key: "tables",
//    route: "/tables",
//    icon: (
//      <ArgonBox component="i" color="warning" fontSize="14px" className="ni ni-calendar-grid-58" />
//    ),
//    component: <Tables />,
//  },
//  {
//    type: "route",
//    name: "Billing",
//    key: "billing",
//    route: "/billing",
//    icon: <ArgonBox component="i" color="success" fontSize="14px" className="ni ni-credit-card" />,
//    component: <Billing />,
//  },
//  {
//    type: "route",
//    name: "Virtual Reality",
//    key: "virtual-reality",
//    route: "/virtual-reality",
//    icon: <ArgonBox component="i" color="info" fontSize="14px" className="ni ni-app" />,
//    component: <VirtualReality />,
//  },
//  {
//    type: "route",
//    name: "RTL",
//    key: "rtl",
//    route: "/rtl",
//    icon: <ArgonBox component="i" color="error" fontSize="14px" className="ni ni-world-2" />,
//    component: <RTL />,
//  },
  { type: "title", title: "Account Pages", key: "account-pages" },
  {
    type: "route",
    name: "Profile",
    key: "profile",
    route: "/profile",
    icon: <ArgonBox component="i" color="dark" fontSize="14px" className="ni ni-single-02" />,
    component: <Profile />,
  },
//  {
//    type: "route",
//    name: "Sign In",
//    key: "sign-in",
//    route: "/authentication/sign-in",
//    icon: (
//      <ArgonBox component="i" color="warning" fontSize="14px" className="ni ni-single-copy-04" />
//    ),
//    component: <SignIn />,
//  },
//  {
//    type: "route",
//    name: "Sign Up",
//    key: "sign-up",
//    route: "/authentication/sign-up",
//    icon: <ArgonBox component="i" color="info" fontSize="14px" className="ni ni-collection" />,
//    component: <SignUp />,
//  },
  {
    type: "route",
    name: "Sign out",
    key: "sign-out",
    route: "/sign-out",
    icon: <Icon color="warning">
      logout
    </Icon>,
    component: <SignOut />,
  },
];

export default routes;
