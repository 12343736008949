/**
=========================================================
* Argon Dashboard 2 MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-material-ui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormHelperText from '@mui/material/FormHelperText';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import Select, { StylesConfig }  from 'react-select'

// @mui icons
//import FacebookIcon from "@mui/icons-material/Facebook";
//import TwitterIcon from "@mui/icons-material/Twitter";
//import InstagramIcon from "@mui/icons-material/Instagram";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import ArgonAvatar from "components/ArgonAvatar";
import ArgonAlert from "components/ArgonAlert";
import ArgonSelect from "components/ArgonSelect";

// Argon Dashboard 2 MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import PlaceholderCard from "examples/Cards/PlaceholderCard";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import profilesListData from "layouts/profile/data/profilesListData";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

import noImage from "assets/images/noImage.jpg";

import React, {useState, useCallback, useEffect, useContext, useRef} from 'react';

import {useDropzone} from 'react-dropzone'

import useToken from 'components/app/useToken';

// react-router-dom components
import { useNavigate, useLocation } from 'react-router-dom';

import EmployeeContext from 'components/app/employee';

// Argon Dashboard 2 PRO MUI context
import { useArgonController } from "context";

// Argon Dashboard 2 PRO MUI base styles
import colors from "assets/theme/base/colors";
import colorsDark from "assets/theme-dark/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import bordersDark from "assets/theme-dark/base/borders";
import boxShadows from "assets/theme/base/boxShadows";
import boxShadowsDark from "assets/theme-dark/base/boxShadows";

// Argon Dashboard 2 PRO MUI helper functions
import pxToRem from "assets/theme/functions/pxToRem";
import rgba from "assets/theme/functions/rgba";

//import resume from "layouts/resume";
import Resume from "components/app/Resume";
const bgImage =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/profile-layout-header.jpg";
function EmployeeSearch() {
  const { token } = useToken();
  const { employee, setEmployee } = useContext(EmployeeContext);
  const criteriaRef = useRef();
  const resultRef = useRef();
  const formatDate = (d) =>
  {
    var datestring = d.getFullYear() + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" +
    ("0" + d.getDate()).slice(-2);
    return datestring;
  }
  
  const [searchLoading,setSearchLoading] = useState(false);
  const [genderList,setGenderList] = useState([]);
  const [ageRangeList,setAgeRangeList] = useState([]);
  const [jobInterestedList,setJobInterestedList] = useState([]);
  const [workplaceZoneList,setWorkplaceZoneList] = useState([]);
  const [expectedSalaryList,setExpectedSalaryList] = useState([]);
  const [thaiListenList,setThaiListenList] = useState([]);
  const [thaiSpeakList,setThaiSpeakList] = useState([]);
  const [thaiReadList,setThaiReadList] = useState([]);
  const [thaiWriteList,setThaiWriteList] = useState([]);

  const [genderValues,setGenderValues] = useState([]);
  const [ageRangeValues,setAgeRangeValues] = useState([]);
  const [expectedSalaryValues,setExpectedSalaryValues] = useState([]);
  
  const [yearInThailand,setYearInThailand] = useState(`undefined`);
  const [workingExperienceYear,setWorkingExperienceYear] = useState(`undefined`);
  const [thaiListenID, setThaiListenID] = useState(null);
  const [thaiSpeakID, setThaiSpeakID] = useState(null);
  const [thaiReadID, setThaiReadID] = useState(null);
  const [thaiWriteID, setThaiWriteID] = useState(null);
  
  const [jobInterestedValue,setJobInterestedValue] = useState(null);
  const [workplaceZoneValue,setWorkplaceZoneValue] = useState(null);
  const [relatedWord,setRelatedWord] = useState('');
  const [thaiAbilityWidth,setThaiAbilityWidth] = useState("10rem");
  
  const [showCriteria,setShowCriteria] = useState(true);
  const [hasSearchResult,setHasSearchResult] = useState(false);
  const [employeeResumeList,setEmployeeResumeList] = useState([]);
  const [employeeJobInterestedList,setEmployeeJobInterestedList] = useState([]);
  const [employeeWorkplaceZoneList,setEmployeeWorkplaceZoneList] = useState([]);
  
  
  const navigate = useNavigate();

  useEffect(()=>{
//    if(token)
    {
      getResumeParamOption();
    }
//    else
//    {
//      navigate('/employee-sign-up?redirect=resume-submit');
//    }
  },[]);
  
  useEffect(()=>{
    if(showCriteria && criteriaRef.current)
    {
      window.scrollTo({ behavior: 'smooth', top: criteriaRef.current.offsetTop });
    }
    else
    {
      if(resultRef.current)
      {
        window.scrollTo({ behavior: 'smooth', top: resultRef.current.offsetTop });
      }
    }
  },[showCriteria]);

  
  //select control*****
  const [controller] = useArgonController();
  const { darkMode } = controller;
  const { borderWidth, borderRadius } = darkMode ? bordersDark : borders;
  const { dark, white, text, light, inputColors, gradients, transparent, background } = darkMode
    ? colorsDark
    : colors;
  const { inputBoxShadow, lg } = darkMode ? boxShadowsDark : boxShadows;
  const { fontFamily, size, fontWeightRegular } = typography;
  //*****
  
  
  const getResumeParamOption = () =>
  {
    fetch('https://jinglejill.com:4100/seekjob/getResumeParamOption',//**
    {
      method: 'POST',
      headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',//**
                },
      body: JSON.stringify({
        modifiedUser: "jill",
      })
    })
    .then((response) => response.json())
    .then((responseData) =>{
      console.log("responseData:"+JSON.stringify(responseData));
  //      this.setState({loading: false});
      if(responseData.success == true)
      {
        console.log("success getResumeParamOption");
        let _genderList = responseData.resumeParamOption.genderList.map(item => ({...item, value: item.GenderID, label: item.Name}))
        setGenderList(_genderList);
        
        let _ageRangeList = responseData.resumeParamOption.ageRangeList.map(item => ({...item, value: item.AgeRangeID, label: item.Name}))
        setAgeRangeList(_ageRangeList);
        
        let _jobInterestedList = responseData.resumeParamOption.jobInterestedList.map(item => ({...item, value: item.JobInterestedID, label: item.Name}))
        setJobInterestedList(_jobInterestedList);
        
        let _workplaceZoneList = responseData.resumeParamOption.workplaceZoneList.map(item => ({...item, value: item.WorkplaceZoneID, label: item.Name}))
        setWorkplaceZoneList(_workplaceZoneList);
        
        let _expectedSalaryList = responseData.resumeParamOption.expectedSalaryList.map(item => ({...item, value: item.ExpectedSalaryID, label: item.Name}))
        setExpectedSalaryList(_expectedSalaryList);

        setThaiListenList(responseData.resumeParamOption.thaiListenList);
        setThaiSpeakList(responseData.resumeParamOption.thaiSpeakList);
        setThaiReadList(responseData.resumeParamOption.thaiReadList);
        setThaiWriteList(responseData.resumeParamOption.thaiWriteList);
        
        setThaiAbilityWidth(responseData.setting.thaiAbilityWidth);
      }
      else
      {
        // error message
        console.log(responseData.message);
 
      }
    }).catch((error) => console.error(error))
  }
    
  const searchResume = () =>
  {
    fetch('https://jinglejill.com:4100/seekjob/searchResume',//**
    {
      method: 'POST',
      headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',//**
                },
      body: JSON.stringify({
        jobInterestedID: jobInterestedValue?jobInterestedValue.JobInterestedID:0,
        workplaceZoneID: workplaceZoneValue?workplaceZoneValue.WorkplaceZoneID:0,
        genderValues: genderValues,
        ageRangeValues: ageRangeValues,
        expectedSalaryValues: expectedSalaryValues,
        yearInThailand: typeof yearInThailand == `undefined`?0:parseFloat(yearInThailand),
        workingExperienceYear: typeof workingExperienceYear == `undefined`?0:parseFloat(workingExperienceYear),
        relatedWord: relatedWord,
        thaiListenID: thaiListenID?parseInt(thaiListenID):0,
        thaiSpeakID: thaiSpeakID?parseInt(thaiSpeakID):0,
        thaiReadID: thaiReadID?parseInt(thaiReadID):0,
        thaiWriteID: thaiWriteID?parseInt(thaiWriteID):0,
        modifiedUser: "jill",
      })
    })
    .then((response) => response.json())
    .then((responseData) =>{
      console.log("responseData:"+JSON.stringify(responseData));
  //      this.setState({loading: false});
      if(responseData.success == true)
      {
        console.log("success search resume");
        setShowCriteria(false);
        setHasSearchResult(true);
        
        let _employeeResumeList = responseData.employeeResumeList;
        _employeeResumeList = _employeeResumeList.map((item)=>
          {
            let _workingExperience = item.WorkingExperience.replace(/(?:\r\n|\r|\n)/g, '<br>');
            let _lines = _workingExperience.split("<br>");
            item.WorkingExperienceLines = _lines;
            return item;
          });
        setEmployeeResumeList(_employeeResumeList);
        
        setEmployeeJobInterestedList(responseData.employeeJobInterestedList);
        setEmployeeWorkplaceZoneList(responseData.employeeWorkplaceZoneList);
      }
      else
      {
        // error message
        console.log(responseData.message);
      }
    }).catch((error) => console.error(error))
  }
  
  const handleGenderChange = () =>
  {
    
  }
  
  const [age, setAge] = useState(10);

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const dot = (color = 'transparent') => ({
    alignItems: 'center',
    display: 'flex',

    ':before': {
      backgroundColor: color,
      borderRadius: 10,
      content: '" "',
      display: 'block',
      marginRight: 8,
      height: 10,
      width: 10,
    },
  });
  let selectSize = "";
  let borderColorValue = inputColors.borderColor.main;
  let borderColorFocused = inputColors.borderColor.focus;
  let boxShadowValue = inputBoxShadow;
  const colourStyles: StylesConfig<ColourOption> = {
    control: (styles,{isFocused,isSelected}) => ({ ...styles,
//      height: selectSizeValue,
      fontSize: selectSize === "small" ? size.xs : size.sm,
      fontWeight: fontWeightRegular,
      backgroundColor: darkMode ? darkMode.main : white.main,
//      border: `${borderWidth[1]} solid`,
      borderRadius: borderRadius.md,
      borderColor: isFocused ? borderColorFocused : borderColorValue,
      boxShadow: isFocused ? boxShadowValue : "none",
      cursor: "pointer",
//    transition: "box-shadow 150ms ease, border-color 150ms ease",
      appearance: "none",
      backgroundClip: "padding-box",
      "&:hover, &:focus": {
        borderColor: "#11cdef",
      },
    }),
    
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: (provided, state) => ({
      display: "flex",
      padding: pxToRem(12),

      "& svg": {
        fill: text.main,
        width: pxToRem(14),
        height: pxToRem(14),
        strokeWidth: 1,
        stroke: text.main,
        transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "rotate(0)",
//        transition: "transform 250ms ease",
      },
    }),
    menu: (provided) => ({
      ...provided,
      minWidth: pxToRem(160),
      boxShadow: lg,
      padding: `${pxToRem(16)} ${pxToRem(8)}`,
      fontSize: size.sm,
      color: text.main,
      textAlign: "left",
      backgroundColor: darkMode ? background.dark : white.main,
      borderRadius: borderRadius.md,
      transformOrigin: "50% 0",
      
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      position: "relative",
      minWidth: pxToRem(160),
      minHeight: "unset",
      padding: `${pxToRem(4.8)} ${pxToRem(100)} ${pxToRem(4.8)} ${pxToRem(16)}`,
      borderRadius: borderRadius.md,
      fontSize: size.sm,
      backgroundColor: state.isSelected ? rgba(light.main, darkMode ? 0.1 : 1) : transparent.main,
      color: darkMode ? white.main : text.main,
      cursor: state.isDisabled ? "not-allowed" : "pointer",
      opacity: state.isDisabled ? 0.5 : 1,
      userSelect: state.isDisabled ? "none" : "auto",
//      transition: "background-color 300ms ease, color 300ms ease",

      "&:after": {
        content: "'Press to select'",
        display: "block",
        fontSize: size.xs,
        position: "absolute",
        right: pxToRem(10),
        top: "52%",
        color: text.main,
        opacity: 0,
        transform: "translateY(-50%)",
//        transition: "opacity 300ms ease",
      },

      "&:hover, &:focus": {
        backgroundColor: state.isDisabled ? transparent.main : rgba(light.main, darkMode ? 0.1 : 1),
        color: state.isDisabled ? "currentColor" : darkMode ? white.main : dark.main,

        "&:after": {
          content: state.isDisabled && "''",
          opacity: 0.5,
        },
      },
    }),
  };
  
  const handleJobInterestedID = (e)=>
  {
    if(e)
    {
      const _jobInterestedValues = jobInterestedList.filter((item)=>{return item.JobInterestedID == e.JobInterestedID});
      setJobInterestedValue(_jobInterestedValues[0]);
    }
    else
    {
      setJobInterestedValue(null);
    }
  }
  
  const handleWorkplaceZoneID = (e)=>
  {
    if(e)
    {
      const _workplaceZoneValues = workplaceZoneList.filter((item)=>{return item.WorkplaceZoneID == e.WorkplaceZoneID});
      setWorkplaceZoneValue(_workplaceZoneValues[0]);
    }
    else
    {
      setWorkplaceZoneValue(null);
    }
  }
  
  const getJobName = (jobInterestedID) =>
  {
    return jobInterestedList.filter((job)=>{return job.JobInterestedID == jobInterestedID})[0].Name;
  }
  
  const getWorkplaceZoneName = (workplaceZoneID) =>
  {
    return workplaceZoneList.filter((workplaceZone)=>{return workplaceZone.WorkplaceZoneID == workplaceZoneID})[0].Name;
  }
  
  const toggleShowCriteria = () =>
  {
    setShowCriteria(!showCriteria);
  }
  
  const handleClearValueAndResult = () =>
  {
    setJobInterestedValue(null);
    setWorkplaceZoneValue(null);

    setGenderValues([]);
    setAgeRangeValues([]);
    setExpectedSalaryValues([]);
    setYearInThailand(`undefined`);
    setWorkingExperienceYear(`undefined`);
    setRelatedWord('');
    setThaiListenID(null);
    setThaiSpeakID(null);
    setThaiReadID(null);
    setThaiWriteID(null);

    setEmployeeResumeList([]);
    setEmployeeJobInterestedList([]);
    setEmployeeWorkplaceZoneList([]);
    setHasSearchResult(false);
  }
  
  
  
  return (
    <DashboardLayout
      sx={{
        backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
          `${linearGradient(
            rgba(gradients.info.main, 0.6),
            rgba(gradients.info.state, 0.6)
          )}, url(${bgImage})`,
        backgroundPositionY: "50%",
      }}
    >
      {!employee && (<DashboardNavbar/>)}
      {employee && (<DashboardNavbar employeeName={employee.Name}/>)}
      <ArgonBox mt={3} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={12} xl={12}>
            <Card ref={criteriaRef}>
              <ArgonBox display="flex" justifyContent="space-between" alignItems="center" px={3} pt={3}>
                <ArgonTypography variant="h6">ค้นหาคนทำงาน</ArgonTypography>
                {showCriteria && hasSearchResult &&
                  (
                   <ArgonButton variant="contained" color="info" onClick={handleClearValueAndResult}>ล้างค่าการค้นหา</ArgonButton>
                )}
              </ArgonBox>
              
              {showCriteria && (
                <ArgonBox component="form" role="form" p={2} mx={3}>
                  <ArgonBox mb={2}>
                    <ArgonTypography variant="caption" color="text">
                      ตำแหน่งงาน
                    </ArgonTypography>
                    <Select placeholder="เลือก..." isClearable={true} options={jobInterestedList} value={jobInterestedValue} styles={colourStyles} theme={(theme) => ({
                                  ...theme,
                                  colors: {
                                    ...theme.colors,
                                    primary25: light.main,
                                    primary: light.main,
                                  },
                    })} onChange={handleJobInterestedID}
                    />
                  </ArgonBox>
                            
                  <ArgonBox mb={2}>
                    <ArgonTypography variant="caption" color="text">
                      โซนสถานที่ทำงาน
                    </ArgonTypography>
                    <Select placeholder="เลือก..." isClearable={true} options={workplaceZoneList} value={workplaceZoneValue} styles={colourStyles} theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: light.main,
                            primary: light.main,
                          },
                    })} onChange={handleWorkplaceZoneID}
                    />
                    
                  </ArgonBox>
                  
                  <ArgonBox mb={2}>
                    <ArgonTypography variant="caption" color="text">
                      เพศ
                    </ArgonTypography>
                    
                    <Select placeholder="เลือก..." options={genderList} value={genderValues} isMulti={true} closeMenuOnSelect={false} styles={colourStyles} theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: light.main,
                            primary: light.main,
                          },
                        })} onChange={(e)=>setGenderValues(e)}
                    />
                    
                  </ArgonBox>
                  <ArgonBox mb={2}>
                    <ArgonTypography variant="caption" color="text">
                      ช่วงอายุ
                    </ArgonTypography>
                    <Select placeholder="เลือก..." options={ageRangeList} value={ageRangeValues} isMulti={true} closeMenuOnSelect={false} styles={colourStyles} theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: light.main,
                            primary: light.main,
                          },
                        })} onChange={(e)=>setAgeRangeValues(e)}
                    />
                  </ArgonBox>
                  
                  <ArgonBox mb={2}>
                    <ArgonTypography variant="caption" color="text">
                      ระดับเงินเดือน (บาท)
                    </ArgonTypography>
                    <Select placeholder="เลือก..." options={expectedSalaryList} value={expectedSalaryValues} isMulti={true} closeMenuOnSelect={false} styles={colourStyles} theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: light.main,
                            primary: light.main,
                          },
                        })} onChange={(e)=>setExpectedSalaryValues(e)}
                    />
                  </ArgonBox>
                  <ArgonBox mb={2}>
                    <ArgonTypography variant="caption" color="text">
                      ระยะเวลาขั้นต่ำอาศัยอยู่ที่ไทย (ปี)
                    </ArgonTypography>
                    <ArgonInput size="small" type="number" placeholder="2.11" inputProps={{  min: "0", step: "1"}} value={yearInThailand} onChange={(e)=>setYearInThailand(e.target.value)}/>
                  </ArgonBox>
                  <ArgonBox mb={2}>
                    <ArgonTypography variant="caption" color="text">
                     ประสบการณ์ทำงานขั้นต่ำ (ปี)
                    </ArgonTypography>
                    <ArgonInput size="small" type="number" placeholder="2.11" inputProps={{  min: "0", step: "1"}} value={workingExperienceYear} onChange={(e)=>setWorkingExperienceYear(e.target.value)}/>
                  </ArgonBox>
                  <ArgonBox mb={2}>
                    <ArgonTypography variant="caption" color="text">
                      คำที่เกี่ยวข้อง (แบ่งคำด้วยเครื่องหมาย ,)
                    </ArgonTypography>
                    <ArgonInput size="small" multiline={true} rows={2} placeholder=
                                'เลี้ยงเด็ก,อ่อนนุช,แม่บ้าน,พระราม 3' value={relatedWord} onChange={(e)=>setRelatedWord(e.target.value)}/>
                  </ArgonBox>
                  
                  <ArgonBox mb={2}>
                    <ArgonTypography variant="caption" color="text">
                      ความสามารถทางภาษาไทยขั้นต่ำ
                    </ArgonTypography>
                            
                    <ArgonBox ml={1.8} display="flex" flexDirection="column">
                      <ArgonTypography variant="caption" color="text">
                        ฟัง
                      </ArgonTypography>
                      <ArgonBox ml={1.2}>
                        <FormControl>
                          <RadioGroup
                            name="radio-buttons-group"
                            value={thaiListenID}
                            onChange={(e)=>{setThaiListenID(e.target.value);}}
                            row
                          >
                           <Grid container columnSpacing={3}>
                           {thaiListenList.map((item)=>
                            (
                             <Grid item xs={12} md={6} xl={3} key={item.ThaiListenID}>
                               <ArgonBox width={thaiAbilityWidth}>
                                 <FormControlLabel value={item.ThaiListenID} control={<Radio />} label={<Box component="div" fontWeight={fontWeightRegular} fontFamily={fontFamily}>
                                   {item.Name}
                                 </Box>} key={item.ThaiListenID}/>
                               </ArgonBox>
                             </Grid>
                            )
                           )}
                           </Grid>
                          </RadioGroup>
                        </FormControl>
                      </ArgonBox>
                    </ArgonBox>
                              
                    <ArgonBox ml={1.8} mt={1} display="flex" flexDirection="column">
                      <ArgonTypography variant="caption" color="text">
                        พูด
                      </ArgonTypography>
                      <ArgonBox ml={1.2}>
                        <FormControl>
                          <RadioGroup
                            name="radio-buttons-group"
                            value={thaiSpeakID}
                            onChange={(e)=>{setThaiSpeakID(e.target.value);}}
                            row
                          >
                           <Grid container columnSpacing={3}>
                           {thaiSpeakList.map((item)=>
                            (
                             <Grid item xs={12} md={6} xl={3} key={item.ThaiSpeakID}>
                               <ArgonBox width={thaiAbilityWidth}>
                                 <FormControlLabel value={item.ThaiSpeakID} control={<Radio />} label={<Box component="div" fontWeight={fontWeightRegular} fontFamily={fontFamily}>
                                   {item.Name}
                                 </Box>} key={item.ThaiSpeakID}/>
                               </ArgonBox>
                             </Grid>
                            )
                           )}
                           </Grid>
                          </RadioGroup>
                        </FormControl>
                      </ArgonBox>
                    </ArgonBox>
                              
                    <ArgonBox ml={1.8} mt={1} display="flex" flexDirection="column">
                      <ArgonTypography variant="caption" color="text">
                        อ่าน
                      </ArgonTypography>
                      <ArgonBox ml={1.2}>
                        <FormControl>
                          <RadioGroup
                            name="radio-buttons-group"
                            value={thaiReadID}
                            onChange={(e)=>{setThaiReadID(e.target.value);}}
                            row
                          >
                           <Grid container columnSpacing={3}>
                           {thaiReadList.map((item)=>
                            (
                             <Grid item xs={12} md={6} xl={3} key={item.ThaiReadID}>
                               <ArgonBox width={thaiAbilityWidth}>
                                 <FormControlLabel value={item.ThaiReadID} control={<Radio />} label={<Box component="div" fontWeight={fontWeightRegular} fontFamily={fontFamily}>
                                   {item.Name}
                                 </Box>} key={item.ThaiReadID}/>
                               </ArgonBox>
                             </Grid>
                            )
                           )}
                           </Grid>
                          </RadioGroup>
                        </FormControl>
                      </ArgonBox>
                    </ArgonBox>
                                        
                    <ArgonBox ml={1.8} mt={1} display="flex" flexDirection="column">
                      <ArgonTypography variant="caption" color="text">
                        เขียน
                      </ArgonTypography>
                      <ArgonBox ml={1.2}>
                        <FormControl>
                          <RadioGroup
                            name="radio-buttons-group"
                            value={thaiWriteID}
                            onChange={(e)=>{setThaiWriteID(e.target.value);}}
                            row
                          >
                           <Grid container columnSpacing={3}>
                           {thaiWriteList.map((item)=>
                            (
                             <Grid item xs={12} md={6} xl={3} key={item.ThaiWriteID}>
                               <ArgonBox width={thaiAbilityWidth}>
                                 <FormControlLabel value={item.ThaiWriteID} control={<Radio />} label={<Box component="div" fontWeight={fontWeightRegular} fontFamily={fontFamily}>
                                   {item.Name}
                                 </Box>} key={item.ThaiWriteID}/>
                               </ArgonBox>
                             </Grid>
                            )
                           )}
                           </Grid>
                          </RadioGroup>
                        </FormControl>
                      </ArgonBox>
                    </ArgonBox>
                              
                  </ArgonBox>
                            
                  <ArgonBox mt={4} mb={1}>
                    <ArgonButton color="info" size="large" fullWidth onClick={searchResume}>
                     {
                       !searchLoading && "Search"
                     }
                     {
                       searchLoading && (<CircularProgress size='1rem' color='white' thickness="4"/>)
                     }
                    </ArgonButton>
                  </ArgonBox>
                </ArgonBox>
              )}
              {showCriteria && hasSearchResult &&
                (
                 <ArgonBox p={2} mx={3} style={{display:'flex',justifyContent:'flex-end'}} onClick={toggleShowCriteria}>
                   <ArgonBox style={{display:'flex',flexDirection:'row',cursor:'pointer'}}>
                     <ArgonTypography variant="caption" color="text">ซ่อนตัวเลือกสำหรับค้นหา</ArgonTypography>
                     <ArrowDropDownIcon style={{transform: "rotate(180deg)"}}/>
                   </ArgonBox>
                 </ArgonBox>
              )}
              {!showCriteria &&
                (
                 <ArgonBox p={2} mx={3} style={{display:'flex',justifyContent:'flex-end'}} onClick={toggleShowCriteria}>
                   <ArgonBox style={{display:'flex',flexDirection:'row',cursor:'pointer'}}>
                     <ArgonTypography variant="caption" color="text">แสดงตัวเลือกสำหรับค้นหา</ArgonTypography>
                     <ArrowDropDownIcon style={{transform: "rotate(0deg)"}}/>
                   </ArgonBox>
                 </ArgonBox>
              )}
              

            </Card>
            <ArgonBox mt={3} mb={3} ref={resultRef}>
             <Grid container spacing={3}>
               {employeeResumeList.map((item)=>
                 (
                  <Grid item xs={12} md={6} xl={4} key={item.EmployeeResumeID}>
                    <Card sx={{ height: "100%" }}>
                      <Resume employeeResume={item} employeeJobInterestedList={employeeJobInterestedList} employeeWorkplaceZoneList={employeeWorkplaceZoneList} jobInterestedList={jobInterestedList} workplaceZoneList={workplaceZoneList}/>
                    </Card>
                  </Grid>
                 )
               )}
             </Grid>
            </ArgonBox>
          </Grid>
        </Grid>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default EmployeeSearch;
