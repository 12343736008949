/**
=========================================================
* Argon Dashboard 2 MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-material-ui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/*global FB*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import PlaceholderCard from "examples/Cards/PlaceholderCard";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import profilesListData from "layouts/profile/data/profilesListData";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";

import React, {useEffect, useContext} from 'react';

// react-router-dom components
import { useNavigate } from 'react-router-dom';

import useToken from 'components/app/useToken';
import EmployeeContext from 'components/app/employee';

const bgImage =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/profile-layout-header.jpg";
function SignOut() {
  const navigate = useNavigate();
  const { token, setToken } = useToken();
  const { employee, setEmployee } = useContext(EmployeeContext);
  
  useEffect( () => {
    console.log("use effect run first time only");
    if(typeof FB == "undefined")
    {
      console.log("fb undefined")
      var _localStorage = {};
      for (var i in localStorage)
      {
        _localStorage[i] = localStorage[i];
      }
      for (const [key, value] of Object.entries(_localStorage)) {
        console.log(key, value);
        if(key.startsWith("fblst_"))
        {
          localStorage.removeItem(key);
          break;
        }
      }

      var _sessionStorage = {};
      for (var i in sessionStorage)
      {
        _sessionStorage[i] = sessionStorage[i];
      }
      for (const [key, value] of Object.entries(_sessionStorage)) {
        console.log(key, value);
        if(key.startsWith("fbssls_"))
        {
          sessionStorage.removeItem(key);
          break;
        }
      }
    }
    else
    {
      console.log("fb defined")
      if (FB.getAuthResponse()) {
        FB.logout(function(response) {
          // user is now logged out
          console.log("FB.logout:"+JSON.stringify(response));
        });
      }      
    }
    setToken({token:''});
    setEmployee(null);
    navigate("/dashboard");
    return () => {
      console.log("This will be logged on unmount");
    }
  },[])
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ArgonBox py={3}>
        <Grid container spacing={3} mb={3}>
       
        </Grid>
        <Grid container spacing={3} mb={3}>
          
        </Grid>
        <Grid container spacing={3}>
          
        </Grid>
      </ArgonBox>
      <ArgonBox py={3}>
        <Grid container spacing={3} mb={3}>
       
        </Grid>
        <Grid container spacing={3} mb={3}>
          
        </Grid>
        <Grid container spacing={3}>
          
        </Grid>
      </ArgonBox>
      <ArgonBox py={3}>
        <Grid container spacing={3} mb={3}>
       
        </Grid>
        <Grid container spacing={3} mb={3}>
          
        </Grid>
        <Grid container spacing={3}>
          
        </Grid>
      </ArgonBox>
      <ArgonBox py={3}>
        <Grid container spacing={3} mb={3}>
       
        </Grid>
        <Grid container spacing={3} mb={3}>
          
        </Grid>
        <Grid container spacing={3}>
          
        </Grid>
      </ArgonBox>
      <ArgonBox py={3}>
        <Grid container spacing={3} mb={3}>
       
        </Grid>
        <Grid container spacing={3} mb={3}>
          
        </Grid>
        <Grid container spacing={3}>
          
        </Grid>
      </ArgonBox>
      
      <Footer />
    </DashboardLayout>
  );
}

export default SignOut;
