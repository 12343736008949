/* eslint-disable no-unused-vars */
/**
=========================================================
* Argon Dashboard 2 MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-material-ui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DetailedStatisticsCard from "examples/Cards/StatisticsCards/DetailedStatisticsCard";
import SalesTable from "examples/Tables/SalesTable";
import CategoriesList from "examples/Lists/CategoriesList";
import GradientLineChart from "examples/Charts/LineCharts/GradientLineChart";

// Argon Dashboard 2 MUI base styles
import typography from "assets/theme/base/typography";

// Dashboard layout components
import Slider from "layouts/dashboard/components/Slider";

// Data
import gradientLineChartData from "layouts/dashboard/data/gradientLineChartData";
import salesTableData from "layouts/dashboard/data/salesTableData";
import categoriesListData from "layouts/dashboard/data/categoriesListData";

import ArgonButton from "components/ArgonButton";
//import { useNavigate } from 'react-router-dom';

import resumeSubmitBg from "assets/images/pexels-yan-krukov-4669018.jpg";

import {useState, useEffect, useContext} from 'react';

import EmployeeContext from 'components/app/employee';

function Default() {
  const { size } = typography;
  const { employee, setEmployee } = useContext(EmployeeContext);
  
  return (
    <DashboardLayout>      
      {!employee && (<DashboardNavbar/>)}
      {employee && (<DashboardNavbar employeeName={employee.Name}/>)}
      <ArgonBox py={0}>
        {/*
        <Grid container spacing={3} mb={3}>
          <Grid item xs={12} md={6} lg={3}>
            <DetailedStatisticsCard
              title="today's money"
              count="$53,000"
              icon={{ color: "info", component: <i className="ni ni-money-coins" /> }}
              percentage={{ color: "success", count: "+55%", text: "since yesterday" }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <DetailedStatisticsCard
              title="today's users"
              count="2,300"
              icon={{ color: "error", component: <i className="ni ni-world" /> }}
              percentage={{ color: "success", count: "+3%", text: "since last week" }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <DetailedStatisticsCard
              title="new clients"
              count="+3,462"
              icon={{ color: "success", component: <i className="ni ni-paper-diploma" /> }}
              percentage={{ color: "error", count: "-2%", text: "since last quarter" }}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <DetailedStatisticsCard
              title="sales"
              count="$103,430"
              icon={{ color: "warning", component: <i className="ni ni-cart" /> }}
              percentage={{ color: "success", count: "+5%", text: "than last month" }}
            />
          </Grid>
        </Grid>
        */}
          
        <Grid container spacing={3} mb={3}>
          <Grid item xs={12} lg={7}>
            <ArgonBox
              sx={{
                position: "relative",
                backgroundImage: `url(${resumeSubmitBg})`,
                backgroundSize: "cover",
                height: "100%",
              }}
              borderRadius="lg"
            >
              <ArgonBox position="absolute" bottom={16} ml={6} py={2.5} textAlign="left" width="80%">
                <ArgonButton href="/resume-submit">ฝากประวัติ</ArgonButton>              
                <ArgonBox mt={2}>
                  <ArgonTypography variant="h5" color="white" mb={0.5} sx={{textShadow: "black 0px 0px 10px"}}>
                    หางาน เปลี่ยนงาน
                  </ArgonTypography>
                  <ArgonTypography variant="body2" color="white" sx={{textShadow: "black 0px 0px 10px"}}>
                    ฝากประวัติได้เลย 24 ชม.
                  </ArgonTypography>
                </ArgonBox>
              </ArgonBox>
            </ArgonBox>
            <ArgonTypography color="white">.</ArgonTypography>        
            <ArgonTypography color="white">.</ArgonTypography>
            <ArgonTypography color="white">.</ArgonTypography>
            <ArgonTypography color="white">.</ArgonTypography>
            <ArgonTypography color="white">.</ArgonTypography>
            <ArgonTypography color="white">.</ArgonTypography>
            <ArgonTypography color="white">.</ArgonTypography>
            <ArgonTypography color="white">.</ArgonTypography>
            <ArgonTypography color="white">.</ArgonTypography>
            <ArgonTypography color="white">.</ArgonTypography>
 
            {/*
            <GradientLineChart
              title="Sales Overview"
              description={
                <ArgonBox display="flex" alignItems="center">
                  <ArgonBox fontSize={size.lg} color="success" mb={0.3} mr={0.5} lineHeight={0}>
                    <Icon sx={{ fontWeight: "bold" }}>arrow_upward</Icon>
                  </ArgonBox>
                  <ArgonTypography variant="button" color="text" fontWeight="medium">
                    4% more{" "}
                    <ArgonTypography variant="button" color="text" fontWeight="regular">
                      in 2022
                    </ArgonTypography>
                  </ArgonTypography>
                </ArgonBox>
              }
              chart={gradientLineChartData}
            />
            */}
          </Grid>
          <Grid item xs={12} lg={5}>
            <Slider />
          </Grid>
        </Grid>
          {/*
        <Grid container spacing={3}>
          <Grid item xs={12} md={8}>
            <SalesTable title="Sales by Country" rows={salesTableData} />
          </Grid>
          <Grid item xs={12} md={4}>
            <CategoriesList title="categories" categories={categoriesListData} />
          </Grid>
        </Grid>
        */}
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Default;
