/**
=========================================================
* Argon Dashboard 2 MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-material-ui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';

// @mui icons
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import ArgonAvatar from "components/ArgonAvatar";
import ArgonAlert from "components/ArgonAlert";

// Argon Dashboard 2 MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProfileInfoCard from "examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "examples/Lists/ProfilesList";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import PlaceholderCard from "examples/Cards/PlaceholderCard";

// Overview page components
import Header from "layouts/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";

// Data
import profilesListData from "layouts/profile/data/profilesListData";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";


import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';

import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";

import pxToRem from "assets/theme/functions/pxToRem";

import {useState, useCallback, useEffect, useContext, useRef} from 'react';

import {useDropzone} from 'react-dropzone'

import useToken from 'components/app/useToken';

// react-router-dom components
import { useNavigate, useLocation } from 'react-router-dom';

import EmployeeContext from 'components/app/employee';
import Resume from "components/app/Resume";

import {getEmployeeResumeByEmployeeID,deleteEmployeeResume,getImage} from 'components/app/dbQuery';

const bgImage =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/argon-dashboard-pro/assets/img/profile-layout-header.jpg";
function ResumeSubmit() {
  const { token } = useToken();
  const { employee, setEmployee } = useContext(EmployeeContext);
  const resultRef = useRef();
  const criteriaRef = useRef();
  const formatDate = (d) =>
  {
    var datestring = d.getFullYear() + "-" + ("0"+(d.getMonth()+1)).slice(-2) + "-" +
    ("0" + d.getDate()).slice(-2);
    return datestring;
  }
  
  const [submitError,setSubmitError] = useState(false);
  const [submitSuccess,setSubmitSuccess] = useState(false);
  const [deleteSuccess,setDeleteSuccess] = useState(false);
  const [submitLoading,setSubmitLoading] = useState(false);
  const [deleteLoading,setDeleteLoading] = useState(false);
  const [showForm,setShowForm] = useState(true);
  const [genderList,setGenderList] = useState([]);
  const [jobInterestedList,setJobInterestedList] = useState([]);
  const [workplaceZoneList,setWorkplaceZoneList] = useState([]);
  const [expectedSalaryList,setExpectedSalaryList] = useState([]);
  const [thaiListenList,setThaiListenList] = useState([]);
  const [thaiSpeakList,setThaiSpeakList] = useState([]);
  const [thaiReadList,setThaiReadList] = useState([]);
  const [thaiWriteList,setThaiWriteList] = useState([]);
  const [jobInterestedWidth,setJobInterestedWidth] = useState("10rem");
  const [workplaceZoneWidth,setWorkplaceZoneWidth] = useState("10rem");
  const [thaiAbilityWidth,setThaiAbilityWidth] = useState("10rem");
  const [base64, setBase64] = useState('');
  const [base64Src, setBase64Src] = useState('');
  const [mimeType, setMimeType] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [imageFileID, setImageFileID] = useState('');
  const [editImage, setEditImage] = useState(false);
  
  
  const [firstName,setFirstName] = useState('');
  const [lastName,setLastName] = useState('');
  const [genderID,setGenderID] = useState('0');
  const [birthdate,setBirthdate] = useState('1900-01-01');
  const [jobInterestedOther, setJobInterestedOther] = useState(false);
  const [jobInterestedOtherText, setJobInterestedOtherText] = useState('');
  const [workplaceZoneOther, setWorkplaceZoneOther] = useState(false);
  const [workplaceZoneOtherText, setWorkplaceZoneOtherText] = useState('');
  const [expectedSalaryID,setExpectedSalaryID] = useState('0');
  const [workingExperienceYear,setWorkingExperienceYear] = useState(`undefined`);
  const [workingExperience,setWorkingExperience] = useState('');
  const [yearInThailand,setYearInThailand] = useState(`undefined`);
  const [thaiListenID, setThaiListenID] = useState('0');
  const [thaiSpeakID, setThaiSpeakID] = useState('0');
  const [thaiReadID, setThaiReadID] = useState('0');
  const [thaiWriteID, setThaiWriteID] = useState('0');
  
  const [employeeResume,setEmployeeResume] = useState(null);
  const [employeeJobInterestedList,setEmployeeJobInterestedList] = useState([]);
  const [employeeWorkplaceZoneList,setEmployeeWorkplaceZoneList] = useState([]);
  const [employeeJobInterestedCheckedList,setEmployeeJobInterestedCheckedList] = useState([]);
  const [employeeWorkplaceZoneCheckedList,setEmployeeWorkplaceZoneCheckedList] = useState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  
  const navigate = useNavigate();
//  const { state } = useLocation();
//  const { employee } = state || {};

  useEffect(()=>{
    async function _getEmployeeResumeByEmployeeID(employeeID) {
      const result = await getEmployeeResumeByEmployeeID(employeeID);
      console.log("_getEmployeeResumeByEmployeeID response:"+JSON.stringify(result))
      if(result.employeeResume)
      {
        let _workingExperience = result.employeeResume.WorkingExperience.replace(/(?:\r\n|\r|\n)/g, '<br>');
        let _lines = _workingExperience.split("<br>");
        result.employeeResume.WorkingExperienceLines = _lines;
        
        setEmployeeResume(result.employeeResume);
        setEmployeeJobInterestedList(result.employeeJobInterestedList);
        setEmployeeWorkplaceZoneList(result.employeeWorkplaceZoneList);
        setShowForm(false);
      }
    }
    
    if(token)
    {
      //get employee resume if yes show resume + edit/delete button
      //if no show resume form
      console.log('resume submit has token');
      console.log("employee json:"+JSON.stringify(employee));
      if(employee)
      {
        getResumeParamOption();
        _getEmployeeResumeByEmployeeID(employee.EmployeeID);
      }      
    }
    else
    {
      console.log('resume submit has no token');
//      navigate('/employee-sign-up?redirect=resume-submit');
      navigate('/employee-sign-up',{state:{redirect:"resume-submit"}});
    }
  },[]);
  
  useEffect(()=>{
    async function _getEmployeeResumeByEmployeeID(employeeID) {
      const result = await getEmployeeResumeByEmployeeID(employeeID);
      console.log("_getEmployeeResumeByEmployeeID response:"+JSON.stringify(result))
      if(result.employeeResume)
      {
        let _workingExperience = result.employeeResume.WorkingExperience.replace(/(?:\r\n|\r|\n)/g, '<br>');
        let _lines = _workingExperience.split("<br>");
        result.employeeResume.WorkingExperienceLines = _lines;
        
        setEmployeeResume(result.employeeResume);
        setEmployeeJobInterestedList(result.employeeJobInterestedList);
        setEmployeeWorkplaceZoneList(result.employeeWorkplaceZoneList);
        setShowForm(false);
      }
    }
    
    if(token)
    {
      //get employee resume if yes show resume + edit/delete button
      //if no show resume form
      console.log('resume submit has token');
      console.log("employee json:"+JSON.stringify(employee));
      if(employee)
      {
        getResumeParamOption();
        _getEmployeeResumeByEmployeeID(employee.EmployeeID);
      }
    }
    else
    {
      console.log('resume submit has no token');
//      navigate('/employee-sign-up?redirect=resume-submit');
      navigate('/employee-sign-up',{state:{redirect:"resume-submit"}});
    }
  },[employee]);

  useEffect(()=>{
    if(employeeResume)
    {
      if(resultRef.current)
      {
        console.log("has resultRef.current");
        window.scrollTo({ behavior: 'smooth', top: resultRef.current.offsetTop });
      }
      else
      {
        console.log("no resultRef.current");
      }
    }
  },[employeeResume]);

  const { fontFamily,fontWeightRegular } = typography;
  
  const { borderRadius } = borders;
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      var reader = new FileReader();
      reader.onloadend = function() {
        console.log('RESULT', reader.result)
        setBase64Src(reader.result);
        setBase64(reader.result.split(',')[1]);
        setMimeType(reader.result.split(';')[0].replace("data:",""));
        setEditImage(true);
      }
      reader.readAsDataURL(file);

    })
    
  }, [])

  
  const {getRootProps, getInputProps} = useDropzone({onDrop})
  
//  //***** interesting template
//  const [inputs, setInputs] = useState({
//    name:"",
//    gender:"",
//  })
//  const handleChange = (e) =>
//  {
//    setInputs((prevState)=>({
//      ...prevState,[e.target.name]: e.target.value,
//    }));
//  }
//  //*****
  
  const handleEmployeeJobInterestedCheckedList = (jobInterestedID, value) =>
  {
    let _employeeJobInterestedCheckedList = employeeJobInterestedCheckedList.map((item)=>
      {
        if(item.JobInterestedID == jobInterestedID)
        {
          item.Checked = value;
        }
        return item;
      }
    );
    setEmployeeJobInterestedCheckedList(_employeeJobInterestedCheckedList);
  }
  
  const handleEmployeeWorkplaceZoneCheckedList = (workplaceZoneID, value) =>
  {
    let _employeeWorkplaceZoneCheckedList = employeeWorkplaceZoneCheckedList.map((item)=>
      {
        if(item.WorkplaceZoneID == workplaceZoneID)
        {
          item.Checked = value;
        }
        return item;
      }
    );
    setEmployeeWorkplaceZoneCheckedList(_employeeWorkplaceZoneCheckedList);
  }
  
  const getResumeParamOption = () =>
  {
    fetch('https://jinglejill.com:4100/seekjob/getResumeParamOption',//**
    {
      method: 'POST',
      headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',//**
                },
      body: JSON.stringify({
        modifiedUser: "jill",
      })
    })
    .then((response) => response.json())
    .then((responseData) =>{
      console.log("responseData:"+JSON.stringify(responseData));
  //      this.setState({loading: false});
      if(responseData.success == true)
      {
        console.log("success getResumeParamOption");
        setGenderList(responseData.resumeParamOption.genderList);
        setJobInterestedList(responseData.resumeParamOption.jobInterestedList);
        setWorkplaceZoneList(responseData.resumeParamOption.workplaceZoneList);
        setExpectedSalaryList(responseData.resumeParamOption.expectedSalaryList);
        setThaiListenList(responseData.resumeParamOption.thaiListenList);
        setThaiSpeakList(responseData.resumeParamOption.thaiSpeakList);
        setThaiReadList(responseData.resumeParamOption.thaiReadList);
        setThaiWriteList(responseData.resumeParamOption.thaiWriteList);
        setJobInterestedWidth(responseData.setting.jobInterestedWidth);
        setWorkplaceZoneWidth(responseData.setting.workplaceZoneWidth);
        setThaiAbilityWidth(responseData.setting.thaiAbilityWidth);
        
        let _employeeJobInterestedCheckedList = responseData.resumeParamOption.jobInterestedList.map(item => ({...item, Checked: false}))
        _employeeJobInterestedCheckedList.forEach((v)=>{ delete v.Name });
        setEmployeeJobInterestedCheckedList(_employeeJobInterestedCheckedList);
        
        
        let _employeeWorkplaceZoneCheckedList = responseData.resumeParamOption.workplaceZoneList.map(item => ({...item, Checked: false}))
        _employeeWorkplaceZoneCheckedList.forEach((v)=>{ delete v.Name });
        setEmployeeWorkplaceZoneCheckedList(_employeeWorkplaceZoneCheckedList);
      }
      else
      {
        // error message
        console.log(responseData.message);
 
      }
    }).catch((error) => console.error(error))
  }
    
  const submitResume = () =>
  {
    if(firstName == '')
    {
      setSubmitError(true);
      setTimeout(()=>setSubmitError(false),5000);
      return;
    }
    
    setSubmitLoading(true);
    
    fetch('https://jinglejill.com:4100/seekjob/submitResume',//**
    {
      method: 'POST',
      headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',//**
                },
      body: JSON.stringify({
        employeeResumeID: employeeResume?employeeResume.EmployeeResumeID:0,
        employeeID: employee.EmployeeID,
        firstName: firstName,
        lastName: lastName,
        genderID: genderID,
        birthdate: birthdate,
        employeeJobInterestedList: employeeJobInterestedCheckedList,
        employeeWorkplaceZoneList: employeeWorkplaceZoneCheckedList,
        jobInterestedOther: jobInterestedOther?jobInterestedOtherText:'',
        workplaceZoneOther: workplaceZoneOther?workplaceZoneOtherText:'',
        expectedSalaryID: expectedSalaryID,
        workingExperienceYear: workingExperienceYear!=`undefined`?parseFloat(workingExperienceYear):workingExperienceYear,
        workingExperience: workingExperience,
        yearInThailand: yearInThailand!=`undefined`?parseFloat(yearInThailand):yearInThailand,
        thaiListenID: thaiListenID,
        thaiSpeakID: thaiSpeakID,
        thaiReadID: thaiReadID,
        thaiWriteID: thaiWriteID,
        base64ProfilePic: base64,
        mimeType: mimeType,
        editImage: editImage,
        imageUrl: imageUrl,
        imageFileID: imageFileID,
        modifiedUser: "jill",
      })
    })
    .then((response) => response.json())
    .then((responseData) =>{
      console.log("responseData:"+JSON.stringify(responseData));
      setSubmitLoading(false);
      if(responseData.success == true)
      {
        console.log("success submit resume");
        setEmployeeResume(responseData.employeeResume);
        setShowForm(false);
        setEmployeeJobInterestedList(responseData.employeeJobInterestedList);
        setEmployeeWorkplaceZoneList(responseData.employeeWorkplaceZoneList);
        
        setSubmitSuccess(true);
        setTimeout(()=>setSubmitSuccess(false), 5000);
        
        let _workingExperience = responseData.employeeResume.WorkingExperience.replace(/(?:\r\n|\r|\n)/g, '<br>');
        let _lines = _workingExperience.split("<br>");
        responseData.employeeResume.WorkingExperienceLines = _lines;
      }
      else
      {
        // error message
        console.log(responseData.message);
 
      }
    }).catch((error) => console.error(error))
  }
  
  const handleDeleteResume = () =>
  {
    setOpenDeleteDialog(true);
  }
  
  const handleEditResume = () =>
  {
//    getResumeParamOption();
    setShowForm(true);
    setForm();
    setEditImage(false);
  }
  
  const handleCloseDeleteDialog = () =>
  {
    setOpenDeleteDialog(false);
  }
  
  const handleDeleteEmployeeResume = async () =>
  {
    setDeleteLoading(true);
    setOpenDeleteDialog(false);
    const success = await deleteEmployeeResume(employeeResume.EmployeeResumeID);
    if(success)
    {
      setDeleteLoading(false);
      setDeleteSuccess(true);
      setTimeout(()=>setDeleteSuccess(false), 5000);
      if(criteriaRef.current)
      {
        window.scrollTo({ behavior: 'smooth', top: criteriaRef.current.offsetTop });
      }
//      getResumeParamOption();
      setEmployeeResume(null);
      setShowForm(true);
      
      //clear form
      clearForm();
    }
  }
  
  const handleDeleteAvatar = () =>
  {
    setBase64Src('');
    setBase64('');
    setMimeType('');
  }
  
  const clearForm = () =>
  {
    setBase64Src('');
    setBase64('');
    setMimeType('');
    setImageUrl('');
    setImageFileID('');
    
    setFirstName('');
    setLastName('');
    setGenderID(0);
    setBirthdate('1900-01-01');
    let _employeeJobInterestedCheckedList = employeeJobInterestedCheckedList.map(item => ({...item, Checked: false}))
    setEmployeeJobInterestedCheckedList(_employeeJobInterestedCheckedList);
    setJobInterestedOther(false);
    setJobInterestedOtherText('');
    
    let _employeeWorkplaceZoneCheckedList = employeeWorkplaceZoneCheckedList.map(item => ({...item, Checked: false}))
    setEmployeeWorkplaceZoneCheckedList(_employeeWorkplaceZoneCheckedList);
    setWorkplaceZoneOther(false);
    setWorkplaceZoneOtherText('');
    setExpectedSalaryID(0);
    setWorkingExperienceYear(`undefined`);
    setYearInThailand(`undefined`);
    setWorkingExperience('');
    setThaiListenID(0);
    setThaiSpeakID(0);
    setThaiReadID(0);
    setThaiWriteID(0);
  }
  
  const setImage = async (imageUrl) =>
  {
    const _base64Src = await getImage(imageUrl);
    setBase64Src(_base64Src);
    setBase64(_base64Src.split(',')[1]);
    setMimeType(_base64Src.split(';')[0].replace("data:",""));
  }

  const setForm = async () =>
  {
    console.log(employeeResume);
    setImage(employeeResume.ImageUrl);
    setImageUrl(employeeResume.ImageUrl);
    setImageFileID(employeeResume.ImageFileID);
    setFirstName(employeeResume.FirstName);
    setLastName(employeeResume.LastName);
    setGenderID(employeeResume.GenderID);
    setBirthdate(employeeResume.Birthdate.substring(0,10));

    //jobInterested
    let _employeeJobInterestedCheckedList = jobInterestedList.map(item => ({...item, Checked: false}))
    _employeeJobInterestedCheckedList.forEach((v)=>{ delete v.Name });
    _employeeJobInterestedCheckedList = _employeeJobInterestedCheckedList.map((item)=>{
      const jobList = employeeJobInterestedList.filter((job)=>(job.JobInterestedID == item.JobInterestedID));
      if(jobList.length > 0)
      {
        item.Checked = true;
      }
      
      return item
    });
    setEmployeeJobInterestedCheckedList(_employeeJobInterestedCheckedList);
    setJobInterestedOther(employeeResume.JobInterestedOther != "");
    setJobInterestedOtherText(employeeResume.JobInterestedOther);

    
    //workplaceZone
    let _employeeWorkplaceZoneCheckedList = workplaceZoneList.map(item => ({...item, Checked: false}))
    _employeeWorkplaceZoneCheckedList.forEach((v)=>{ delete v.Name });
    _employeeWorkplaceZoneCheckedList = _employeeWorkplaceZoneCheckedList.map((item)=>{
      const zoneList = employeeWorkplaceZoneList.filter((zone)=>(zone.WorkplaceZoneID == item.WorkplaceZoneID));
      if(zoneList.length > 0)
      {
        item.Checked = true;
      }
      return item
    });
    setEmployeeWorkplaceZoneCheckedList(_employeeWorkplaceZoneCheckedList);
    setWorkplaceZoneOther(employeeResume.WorkplaceZoneOther != "");
    setWorkplaceZoneOtherText(employeeResume.WorkplaceZoneOther);
    
    
    setExpectedSalaryID(employeeResume.ExpectedSalaryID);
    setWorkingExperienceYear(employeeResume.WorkingExperienceYear);
    setYearInThailand(employeeResume.YearInThailand);
    setWorkingExperience(employeeResume.WorkingExperience);
    setThaiListenID(employeeResume.ThaiListenID);
    setThaiSpeakID(employeeResume.ThaiSpeakID);
    setThaiReadID(employeeResume.ThaiReadID);
    setThaiWriteID(employeeResume.ThaiWriteID);
  }
  
  const getEmployeeJobInterestedValue = (jobInterestedID) =>
  {
    const _jobInterestedList = employeeJobInterestedCheckedList.filter((item)=>{return item.JobInterestedID == jobInterestedID});
    if(_jobInterestedList.length > 0)
    {
      return _jobInterestedList[0].Checked;
    }
    return false;//
  }
  
  const getEmployeeWorkplaceZoneValue = (workplaceZoneID) =>
  {
    const _workplaceZoneList = employeeWorkplaceZoneCheckedList.filter((item)=>{return item.WorkplaceZoneID == workplaceZoneID});
    if(_workplaceZoneList.length > 0)
    {
      return _workplaceZoneList[0].Checked;
    }
    return false;
  }
  return (
    <DashboardLayout
      sx={{
        backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
          `${linearGradient(
            rgba(gradients.info.main, 0.6),
            rgba(gradients.info.state, 0.6)
          )}, url(${bgImage})`,
        backgroundPositionY: "50%",
      }}
    >
      {!employee && (<DashboardNavbar/>)}
      {employee && (<DashboardNavbar employeeName={employee.Name}/>)}
      <ArgonBox mt={3} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} md={12} xl={12}>
            <Card ref={criteriaRef}>
              {
                submitSuccess && (<ArgonBox mt={2} mb={0} px={3}><ArgonAlert color="info">ฝากประวัติสำเร็จ</ArgonAlert></ArgonBox>)
              }
              {
                deleteSuccess && (<ArgonBox mt={2} mb={0} px={3}><ArgonAlert color="info">ลบประวัติสำเร็จ</ArgonAlert></ArgonBox>)
              }
              
              <ArgonBox display="flex" justifyContent="space-between" alignItems="center" px={3} pt={3}>
                <ArgonTypography variant="h6">ฝากประวัติ</ArgonTypography>
              </ArgonBox>
              {showForm &&
                (
                 <ArgonBox component="form" role="form" p={2} mx={3}>
                   <ArgonBox mb={2}>
                     <ArgonTypography variant="caption" color="text">
                       รูป
                     </ArgonTypography>
                     <ArgonBox mb={2} borderRadius="md" border={1} borderColor={borders.borderColor} sx={{position:'relative'}}>
                       <div {...getRootProps()} style={{cursor:'pointer'}}>
                         <input {...getInputProps()}/>
                         {
                           !base64 &&
                           (
                            <ArgonBox sx={{display:'flex',flexDirection:'column',alignItems:"center", justifyContent:"center",height: pxToRem(120)}} >
                            <ArgonTypography component="label" variant="button" style={{cursor:'pointer'}}>
                                +
                              </ArgonTypography>
                            <ArgonTypography component="label" variant="button" fontWeight="regular"  style={{cursor:'pointer'}}>
                                เพิ่มรูปภาพ
                              </ArgonTypography>
                            </ArgonBox>
                           )
                         }
                         {
                           base64 &&
                           (
                            <ArgonBox sx={{position:'relative',display:'flex',flexDirection:'column',alignItems:"center", justifyContent:"center",height: pxToRem(120)}} >
                              <ArgonAvatar src={base64Src} alt="Avatar" size="xxl" variant="square"/>
                              
                            </ArgonBox>
                           )
                         }
                       </div>
                     {base64 && <DeleteIcon color="error" style={{cursor:'pointer'}} sx={{position:'absolute',right:pxToRem(10),top:pxToRem(10)}} onClick={handleDeleteAvatar}/>}
                       
                     </ArgonBox>
                   </ArgonBox>
                   <ArgonBox mb={2}>
                     <ArgonTypography variant="caption" color="text">
                       ชื่อ
                     </ArgonTypography>
                     <ArgonInput size="small" value={firstName} onChange={(e)=>setFirstName(e.target.value)} error={submitError}/>
                   </ArgonBox>
                   <ArgonBox mb={2}>
                     <ArgonTypography variant="caption" color="text">
                       นามสกุล
                     </ArgonTypography>
                 <ArgonInput size="small" value={lastName} onChange={(e)=>setLastName(e.target.value)}/>
                   </ArgonBox>
                   <ArgonBox mb={2}>
                     <ArgonTypography variant="caption" color="text">
                       เพศ
                     </ArgonTypography>
                     <ArgonBox ml={3}>
                       <FormControl>
                         <RadioGroup
                           name="radio-buttons-group"
                           value={genderID}
                           onChange={(e)=>{setGenderID(e.target.value);}}
                         >
                           {genderList.map((item)=>
                             (
                              <FormControlLabel value={item.GenderID} control={<Radio />} label={<Box component="div" fontWeight={fontWeightRegular} fontFamily={fontFamily}>
                                {item.Name}
                              </Box>} key={item.GenderID} />
                             )
                           )}
                         </RadioGroup>
                       </FormControl>
                     </ArgonBox>
                   </ArgonBox>
                   <ArgonBox mb={2}>
                     <ArgonTypography variant="caption" color="text">
                       วัน เดือน ปี เกิด
                     </ArgonTypography>
                     <ArgonInput size="small" type="date" value={birthdate} onChange={(e)=>setBirthdate(e.target.value)}/>
                   </ArgonBox>
                   <ArgonBox mb={2}>
                     <ArgonTypography variant="caption" color="text">
                       เลือกงานที่ต้องการ
                     </ArgonTypography>
                     <ArgonBox display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" >
                       {jobInterestedList.map((item)=>
                       (
                        <ArgonBox px={1} display="flex" flexDirection="row" alignItems="center" width={jobInterestedWidth} height={"2rem"} key={item.JobInterestedID}>
                          <Checkbox checked={getEmployeeJobInterestedValue(item.JobInterestedID)} onChange={(e)=>handleEmployeeJobInterestedCheckedList(item.JobInterestedID,e.target.checked)}/>
                          <ArgonTypography
                           variant="button"
                           fontWeight="regular"
                           sx={{ cursor: "pointer", userSelect: "none" }}
                          >
                            {item.Name}
                           </ArgonTypography>
                         </ArgonBox>
                         )
                       )
                     }
                     </ArgonBox>
                             
                     <ArgonBox pl={1} display="flex" flexDirection="row" alignItems="center" height={"2rem"}>
                       <Checkbox checked={jobInterestedOther} onChange={(e)=>{setJobInterestedOther(e.target.checked);}}/>
                       <ArgonTypography
                         variant="button"
                         fontWeight="regular"
                         sx={{ cursor: "pointer", userSelect: "none" }}
                       >
                         อื่นๆ&nbsp;&nbsp;
                       </ArgonTypography>
                       {jobInterestedOther &&
                         (
                          <ArgonInput size="small" placeholder="โปรดระบุงานที่ต้องการ" value={jobInterestedOtherText} onChange={(e)=>setJobInterestedOtherText(e.target.value)}/>
                         )
                       }
                     </ArgonBox>
                   </ArgonBox>
                             
                   <ArgonBox mb={2}>
                     <ArgonTypography variant="caption" color="text">
                       โซนสถานที่ทำงาน
                     </ArgonTypography>
                     <ArgonBox display="flex" flexDirection="row" flexWrap="wrap" alignItems="center" >
                       {workplaceZoneList.map((item)=>
                         (
                          <ArgonBox px={1} display="flex" flexDirection="row" alignItems="center" width={workplaceZoneWidth} height={"2rem"} key={item.WorkplaceZoneID}>
                            <Checkbox checked={getEmployeeWorkplaceZoneValue(item.WorkplaceZoneID)} onChange={(e)=>handleEmployeeWorkplaceZoneCheckedList(item.WorkplaceZoneID,e.target.checked)}/>
                            <ArgonTypography
                              variant="button"
                              fontWeight="regular"
                              sx={{ cursor: "pointer", userSelect: "none" }}
                            >
                              {
                                item.Name
                              }
                            </ArgonTypography>
                           </ArgonBox>
                         )
                       )}
                     </ArgonBox>
                             
                     <ArgonBox pl={1} display="flex" flexDirection="row" alignItems="center" height={"2rem"}>
                       <Checkbox checked={workplaceZoneOther} onChange={(e)=>{setWorkplaceZoneOther(e.target.checked);}}/>
                       <ArgonTypography
                         variant="button"
                         fontWeight="regular"
                         sx={{ cursor: "pointer", userSelect: "none" }}
                       >
                         อื่นๆ&nbsp;&nbsp;
                       </ArgonTypography>
                       {workplaceZoneOther &&
                         (
                          <ArgonInput size="small" placeholder="โปรดระบุโซนสถานที่ทำงาน" value={workplaceZoneOtherText} onChange={(e)=>setWorkplaceZoneOtherText(e.target.value)}/>
                         )
                       }
                     </ArgonBox>
                   </ArgonBox>
                   <ArgonBox mb={2}>
                     <ArgonTypography variant="caption" color="text">
                       เงินเดือนที่ต้องการ
                     </ArgonTypography>
                     <ArgonBox ml={3}>
                       <FormControl>
                         <RadioGroup
                           name="radio-buttons-group"
                           value={expectedSalaryID}
                           onChange={(e)=>{setExpectedSalaryID(e.target.value);}}
                         >
                           {
                             expectedSalaryList.map((item)=>
                             (
                              <FormControlLabel value={item.ExpectedSalaryID} control={<Radio />} label={<Box component="div" fontWeight={fontWeightRegular} fontFamily={fontFamily}>
                                {item.Name}
                              </Box>} key={item.ExpectedSalaryID}/>
                             ))
                           }
                   
                         </RadioGroup>
                       </FormControl>
                     </ArgonBox>
                   </ArgonBox>
                   
                   <ArgonBox mb={2}>
                    <ArgonTypography variant="caption" color="text">
                      ประสบการณ์การทำงาน (ปี)
                    </ArgonTypography>
                              <ArgonInput size="small" type="number" placeholder="2.11" inputProps={{  min: "0", step: "1"}} value={workingExperienceYear} onChange={(e)=>setWorkingExperienceYear(e.target.value)}/>
                   </ArgonBox>
                   <ArgonBox mb={2}>
                     <ArgonTypography variant="caption" color="text">
                       ทำอะไรมาบ้าง ที่ไหน นานเท่าไร
                     </ArgonTypography>
                     <ArgonInput size="small" multiline={true} rows={3} placeholder=
                              '- เลี้ยงเด็ก ที่อ่อนนุช เวลา 1.2 ปี&#10;- แม่บ้าน ที่พระราม 3 เวลา 1 ปี' value={workingExperience} onChange={(e)=>setWorkingExperience(e.target.value)}/>
                   </ArgonBox>
                   <ArgonBox mb={2}>
                     <ArgonTypography variant="caption" color="text">
                       รวมระยะเวลาอยู่ที่ไทย (ปี)
                     </ArgonTypography>
                     <ArgonInput size="small" type="number" placeholder="2.11" inputProps={{  min:  "0", step: "1"}} value={yearInThailand} onChange={(e)=>setYearInThailand(e.target.value)}/>
                   </ArgonBox>
                   <ArgonBox mb={2}>
                     <ArgonTypography variant="caption" color="text">
                       ความสามารถทางภาษาไทย
                     </ArgonTypography>
                             
                      <ArgonBox ml={1.8} display="flex" flexDirection="column">
                        <ArgonTypography variant="caption" color="text">
                          ฟัง
                        </ArgonTypography>
                        <ArgonBox ml={1.2}>
                          <FormControl>
                            <RadioGroup
                              name="radio-buttons-group"
                              value={thaiListenID}
                              onChange={(e)=>{setThaiListenID(e.target.value);}}
                              row
                            >
                             <Grid container columnSpacing={3}>
                             {thaiListenList.map((item)=>
                              (
                               <Grid item xs={12} md={6} xl={3} key={item.ThaiListenID}>
                                 <ArgonBox width={thaiAbilityWidth} key={item.ThaiListenID}>
                                   <FormControlLabel value={item.ThaiListenID} control={<Radio />} label={<Box component="div" fontWeight={fontWeightRegular} fontFamily={fontFamily}>
                                     {item.Name}
                                   </Box>} key={item.ThaiListenID}/>
                                 </ArgonBox>
                               </Grid>
                              )
                             )}
                             </Grid>
                            </RadioGroup>
                          </FormControl>
                        </ArgonBox>
                      </ArgonBox>
                                
                      <ArgonBox ml={1.8} mt={1} display="flex" flexDirection="column">
                        <ArgonTypography variant="caption" color="text">
                          พูด
                        </ArgonTypography>
                        <ArgonBox ml={1.2}>
                          <FormControl>
                            <RadioGroup
                              name="radio-buttons-group"
                              value={thaiSpeakID}
                              onChange={(e)=>{setThaiSpeakID(e.target.value);}}
                              row
                            >
                             <Grid container columnSpacing={3}>
                             {thaiSpeakList.map((item)=>
                              (
                               <Grid item xs={12} md={6} xl={3} key={item.ThaiSpeakID}>
                                 <ArgonBox width={thaiAbilityWidth}>
                                   <FormControlLabel value={item.ThaiSpeakID} control={<Radio />} label={<Box component="div" fontWeight={fontWeightRegular} fontFamily={fontFamily}>
                                     {item.Name}
                                   </Box>} key={item.ThaiSpeakID}/>
                                 </ArgonBox>
                               </Grid>
                              )
                             )}
                             </Grid>
                            </RadioGroup>
                          </FormControl>
                        </ArgonBox>
                      </ArgonBox>
                                
                      <ArgonBox ml={1.8} mt={1} display="flex" flexDirection="column">
                        <ArgonTypography variant="caption" color="text">
                          อ่าน
                        </ArgonTypography>
                        <ArgonBox ml={1.2}>
                          <FormControl>
                            <RadioGroup
                              name="radio-buttons-group"
                              value={thaiReadID}
                              onChange={(e)=>{setThaiReadID(e.target.value);}}
                              row
                            >
                             <Grid container columnSpacing={3}>
                             {thaiReadList.map((item)=>
                              (
                               <Grid item xs={12} md={6} xl={3} key={item.ThaiReadID}>
                                 <ArgonBox width={thaiAbilityWidth}>
                                   <FormControlLabel value={item.ThaiReadID} control={<Radio />} label={<Box component="div" fontWeight={fontWeightRegular} fontFamily={fontFamily}>
                                     {item.Name}
                                   </Box>} key={item.ThaiReadID}/>
                                 </ArgonBox>
                               </Grid>
                              )
                             )}
                             </Grid>
                            </RadioGroup>
                          </FormControl>
                        </ArgonBox>
                      </ArgonBox>
                                          
                      <ArgonBox ml={1.8} mt={1} display="flex" flexDirection="column">
                        <ArgonTypography variant="caption" color="text">
                          เขียน
                        </ArgonTypography>
                        <ArgonBox ml={1.2}>
                          <FormControl>
                            <RadioGroup
                              name="radio-buttons-group"
                              value={thaiWriteID}
                              onChange={(e)=>{setThaiWriteID(e.target.value);}}
                              row
                            >
                             <Grid container columnSpacing={3}>
                             {thaiWriteList.map((item)=>
                              (
                               <Grid item xs={12} md={6} xl={3} key={item.ThaiWriteID}>
                                 <ArgonBox width={thaiAbilityWidth}>
                                   <FormControlLabel value={item.ThaiWriteID} control={<Radio />} label={<Box component="div" fontWeight={fontWeightRegular} fontFamily={fontFamily}>
                                     {item.Name}
                                   </Box>} key={item.ThaiWriteID}/>
                                 </ArgonBox>
                               </Grid>
                              )
                             )}
                             </Grid>
                            </RadioGroup>
                          </FormControl>
                        </ArgonBox>
                      </ArgonBox>
                               
                   </ArgonBox>
                             
                   <ArgonBox mt={4} mb={1}>
                     <ArgonButton color="info" size="large" fullWidth onClick={submitResume}>
                       {!submitLoading && "Submit"}
                       {submitLoading && (<CircularProgress size='1rem' color='white' thickness={4}/>)}
                     </ArgonButton>
                   </ArgonBox>
                   {
                     submitError && (<ArgonBox mt={2} mb={1}><ArgonAlert color="error">กรุณาระบุชื่อ</ArgonAlert></ArgonBox>)
                   }
                 </ArgonBox>                               
              )}
              {
                !showForm && (
                  
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6} xl={4}>
                       <ArgonBox ref={resultRef} mx={3} sx={{display:'flex',flexDirection:'row',position:'relative'}}>
                         <Resume employeeResume={employeeResume} employeeJobInterestedList={employeeJobInterestedList} employeeWorkplaceZoneList={employeeWorkplaceZoneList} jobInterestedList={jobInterestedList} workplaceZoneList={workplaceZoneList}/>
                          <ArgonBox sx={{display:'flex',flexDirection:'row',position:'absolute',right:pxToRem(10),top:pxToRem(20)}}>
                            <ArgonBox pr={1}>
                              {!deleteLoading &&
                                (
                                 <DeleteIcon color="error" style={{cursor:'pointer'}} onClick={handleDeleteResume} />
                              )}
                              {deleteLoading &&
                                (
                                 <CircularProgress size='1rem' color='error' thickness={4}/>
                              )}
 
                            </ArgonBox>
                            <ArgonBox>
                              <EditIcon color="info" style={{cursor:'pointer'}} onClick={handleEditResume}/>
                            </ArgonBox>
                          </ArgonBox>
                        </ArgonBox>
                      </Grid>
                    </Grid>
                    
                )
              }
            </Card>
            <Dialog
              open={openDeleteDialog}
              onClose={handleCloseDeleteDialog}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"ลบประวัติ"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  คุณยืนยันที่จะลบประวัติใช่หรือไม่
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDeleteDialog}>ไม่</Button>
                <Button onClick={handleDeleteEmployeeResume} autoFocus>
                  ใช่
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        </Grid>
      </ArgonBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ResumeSubmit;
