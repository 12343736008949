import React from 'react';
import {useState, useEffect} from 'react';

import PropTypes from "prop-types";

import Card from "@mui/material/Card";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonAvatar from "components/ArgonAvatar";

import noImage from "assets/images/noImage.jpg";

import {getImage} from 'components/app/dbQuery';


const Resume = (props) => {
  const item = props.employeeResume;
  const employeeJobInterestedList = props.employeeJobInterestedList;
  const employeeWorkplaceZoneList = props.employeeWorkplaceZoneList;
  const jobInterestedList = props.jobInterestedList;
  const workplaceZoneList = props.workplaceZoneList;
  const [base64Src, setBase64Src] = useState('');

  const getJobName = (jobInterestedID) =>
  {
    const _jobInterestedList = jobInterestedList.filter((job)=>{return job.JobInterestedID == jobInterestedID})
    if(_jobInterestedList.length > 0)
    {
      return _jobInterestedList[0].Name;
    }
    return "";
  }
  const getWorkplaceZoneName = (workplaceZoneID) =>
  {
    const _workplaceZoneList = workplaceZoneList.filter((workplaceZone)=>{return workplaceZone.WorkplaceZoneID == workplaceZoneID});
    if(_workplaceZoneList.length > 0)
    {
      return _workplaceZoneList[0].Name;
    }
    return "";    
  }
  const handleImgError = (e) =>
  {
    e.target.src = noImage;
  }
  
  useEffect(()=>{
    async function _getImage(imageUrl)
    {
      const _base64Src = await getImage(imageUrl);
      console.log("_base64Src:"+_base64Src);
      setBase64Src(_base64Src);
    }
    if(item.ImageUrl != "")
    {
      _getImage(item.ImageUrl);
    }    
  },[]);
  
  
  
//  src={item.ImageUrl == ""?noImage:item.base64Src}
// src={base64Src == ""?noImage:base64Src}
  return (
    
    <ArgonBox mr={3} p={2} style={{display:'flex',flexDirection:'column'}}>
      <ArgonBox py={1} style={{display:'flex',flexDirection:'row'}}>
          {/*<img src={item.ImageUrl == ""?noImage:item.ImageUrl} onError={handleImgError} />*/}
        <ArgonAvatar
                src={base64Src}
                alt="profile-image"
                variant="rounded"
                size="xxl"
                shadow="md"
          onError={handleImgError}
              />
          
      </ArgonBox>
      <ArgonBox py={1} style={{display:'flex',flexDirection:'row'}}>
        <ArgonTypography variant="button" fontWeight="bold">
          ชื่อ: 
        </ArgonTypography>
        <ArgonTypography variant="button" fontWeight="regular" color="text" pl={1}>
          {item.FirstName + ' ' + item.LastName}
        </ArgonTypography>
      </ArgonBox>
      <ArgonBox py={1} style={{display:'flex',flexDirection:'row'}}>
        <ArgonTypography variant="button" fontWeight="bold">
          เพศ: 
        </ArgonTypography>
        <ArgonTypography variant="button" fontWeight="regular" color="text" pl={1}>
          {item.Gender}
        </ArgonTypography>
      </ArgonBox>
      <ArgonBox py={1} style={{display:'flex',flexDirection:'row'}}>
        <ArgonTypography variant="button" fontWeight="bold">
          อายุ: 
        </ArgonTypography>
        <ArgonTypography variant="button" fontWeight="regular" color="text" pl={1}>
          {item.Age>100?'-':item.Age}
        </ArgonTypography>
      </ArgonBox>
      <ArgonBox py={1} style={{display:'flex',flexDirection:'column'}}>
        <ArgonTypography variant="button" fontWeight="bold">
          งานที่ต้องการ: 
        </ArgonTypography>                
        <ArgonBox pl={2} style={{display:'flex',flexDirection:'column'}}>
        {
          employeeJobInterestedList.map((job,index)=>{
            if(job.EmployeeResumeID == item.EmployeeResumeID)
            {
              return (<ArgonTypography variant="button" fontWeight="regular" color="text" key={index}>- {getJobName(job.JobInterestedID)}</ArgonTypography>);
            }
            else
            {
              return <React.Fragment key={index}></React.Fragment>;
            }
          })
        }
        {
         item.JobInterestedOther.trim() != "" && (<ArgonTypography variant="button" fontWeight="regular" color="text">- {item.JobInterestedOther}</ArgonTypography>)
        }
        {
          employeeJobInterestedList.filter((job)=>{return job.EmployeeResumeID == item.EmployeeResumeID}).length == 0 && item.JobInterestedOther.trim() == "" && (<ArgonTypography variant="button" fontWeight="regular" color="text">-</ArgonTypography>)
        }
        </ArgonBox>
      </ArgonBox>
      <ArgonBox py={1} style={{display:'flex',flexDirection:'column'}}>
        <ArgonTypography variant="button" fontWeight="bold">
          โซนสถานที่ทำงาน:
        </ArgonTypography>              
        <ArgonBox pl={2} style={{display:'flex',flexDirection:'column'}}>
        {
          employeeWorkplaceZoneList.map((workplaceZone,index)=>{
            if(workplaceZone.EmployeeResumeID == item.EmployeeResumeID)
            {
              return (<ArgonTypography variant="button" fontWeight="regular" color="text" key={index}>- {getWorkplaceZoneName(workplaceZone.WorkplaceZoneID)}</ArgonTypography>);
            }
            else
            {
              return <React.Fragment key={index}></React.Fragment>;
            }
          })
        }
        {
          item.WorkplaceZoneOther.trim() != "" && (<ArgonTypography variant="button" fontWeight="regular" color="text">- {item.WorkplaceZoneOther}</ArgonTypography>)
        }
        {
          employeeWorkplaceZoneList.filter((job)=>{return job.EmployeeResumeID == item.EmployeeResumeID}).length == 0 && item.WorkplaceZoneOther.trim() == "" && (<ArgonTypography variant="button" fontWeight="regular" color="text">-</ArgonTypography>)
        }
        </ArgonBox>
      </ArgonBox>
      <ArgonBox py={1} style={{display:'flex',flexDirection:'row'}}>
        <ArgonTypography variant="button" fontWeight="bold">
          เงินเดือนที่ต้องการ: 
        </ArgonTypography>
        <ArgonTypography variant="button" fontWeight="regular" color="text" pl={1}>
          {item.ExpectedSalary} บาท
        </ArgonTypography>
      </ArgonBox>
      <ArgonBox py={1} style={{display:'flex',flexDirection:'row'}}>
        <ArgonTypography variant="button" fontWeight="bold">
          ประสบการณ์การทำงาน: 
        </ArgonTypography>
        <ArgonTypography variant="button" fontWeight="regular" color="text" pl={1}>
          {item.WorkingExperienceYear == `undefined`?"-":item.WorkingExperienceYear} ปี
        </ArgonTypography>
      </ArgonBox>
      <ArgonBox py={1} style={{display:'flex',flexDirection:'column'}}>
        <ArgonTypography variant="button" fontWeight="bold">
          ทำอะไรมาบ้าง ที่ไหน นานเท่าไร: 
        </ArgonTypography> 
        <ArgonBox pl={2} style={{display:'flex',flexDirection:'column'}}>
        {
          item.WorkingExperience.trim() != "" && item.WorkingExperienceLines.map((item2,index)=>
            (<ArgonTypography variant="button" fontWeight="regular" color="text" key={index}>{item2}</ArgonTypography>)
        )}
        {item.WorkingExperience.trim() == "" && (<ArgonTypography variant="button" fontWeight="regular" color="text">-</ArgonTypography>)}
        </ArgonBox>       
      </ArgonBox>            
      <ArgonBox py={1} style={{display:'flex',flexDirection:'row'}}>
        <ArgonTypography variant="button" fontWeight="bold">
          รวมระยะเวลาอยู่ที่ไทย: 
        </ArgonTypography>
        <ArgonTypography variant="button" fontWeight="regular" color="text" pl={1}>
          {item.YearInThailand == `undefined`?"-":item.YearInThailand} ปี
        </ArgonTypography>
      </ArgonBox>
      <ArgonBox py={1} style={{display:'flex',flexDirection:'column'}}>
        <ArgonTypography variant="button" fontWeight="bold">
          ความสามารถทางภาษาไทย: 
        </ArgonTypography>   
        <ArgonBox pl={2} style={{display:'flex',flexDirection:'column'}}>   
          <ArgonBox style={{display:'flex',flexDirection:'row'}}>
            <ArgonTypography variant="button" fontWeight="bold">
              ฟัง: 
            </ArgonTypography>   
            <ArgonTypography variant="button" fontWeight="regular" color="text" pl={1}>
              {item.ThaiListen}
            </ArgonTypography>   
          </ArgonBox>
          <ArgonBox style={{display:'flex',flexDirection:'row'}}>
            <ArgonTypography variant="button" fontWeight="bold">
              พูด: 
            </ArgonTypography>   
            <ArgonTypography variant="button" fontWeight="regular" color="text" pl={1}>
              {item.ThaiSpeak}
            </ArgonTypography>   
          </ArgonBox>
          <ArgonBox style={{display:'flex',flexDirection:'row'}}>
            <ArgonTypography variant="button" fontWeight="bold">
              อ่าน: 
            </ArgonTypography>   
            <ArgonTypography variant="button" fontWeight="regular" color="text" pl={1}>
              {item.ThaiRead}
            </ArgonTypography>   
          </ArgonBox>
          <ArgonBox style={{display:'flex',flexDirection:'row'}}>
            <ArgonTypography variant="button" fontWeight="bold">
              เขียน: 
            </ArgonTypography>   
            <ArgonTypography variant="button" fontWeight="regular" color="text" pl={1}>
              {item.ThaiWrite}
            </ArgonTypography>   
          </ArgonBox> 
        </ArgonBox> 
      </ArgonBox>           
    </ArgonBox>
  );
}

Resume.propTypes = {
  employeeResume: PropTypes.object,
  employeeJobInterestedList: PropTypes.array,
  employeeWorkplaceZoneList: PropTypes.array,
  jobInterestedList: PropTypes.array,
  workplaceZoneList: PropTypes.array,
};

export default Resume;


