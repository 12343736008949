/**
=========================================================
* Argon Dashboard 2 MUI - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-material-ui
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";

// Argon Dashboard 2 MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// custom styles for the LanguageItem
import { menuItem, menuImage } from "examples/Items/LanguageItem/styles";

const LanguageItem = forwardRef(({ color, image, title, date, ...rest }, ref) => (
  <MenuItem {...rest} ref={ref} sx={(theme) => menuItem(theme)}>
    <ArgonBox
      width="1.75rem"
      height="1.75rem"
      mt={0.25}
      mr={2}
      mb={0.25}
      borderRadius="md"
      sx={(theme) => menuImage(theme, { color })}
    >
      {image}
    </ArgonBox>
    <ArgonBox>
      <ArgonTypography variant="button" textTransform="capitalize" fontWeight="regular">
        {title[1]}
      </ArgonTypography>
    </ArgonBox>
  </MenuItem>
));

// Setting default values for the props of LanguageItem
LanguageItem.defaultProps = {
  color: "dark",
};

// Typechecking props for the LanguageItem
LanguageItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  image: PropTypes.node.isRequired,
  title: PropTypes.arrayOf(PropTypes.string).isRequired,
  date: PropTypes.string.isRequired,
};

export default LanguageItem;
